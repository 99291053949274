<template>
  <div class="home">
    <TheHome />
  </div>
</template>
<script>
// @ is an alias to /src
import TheHome from "@/components/TheHome.vue";

export default {
  name: "HomeView",
  components: {
    TheHome,
  },
};
</script>

<style scoped>
.home {
  margin: 20px;
}
</style>
