<template>
    <div class="the-home">
      <header class="header">
      <a href="https://inflowaccount.co.th/">
        <img :src="require('@/assets/new_logo-1.png')" alt="Logo" class="logo" />
      </a>
      <h1></h1>
    </header>
  <b-container style="margin-top: 100px;">

    <b-row >
      <b-col
        style="
          box-shadow: darkgrey 1px 1px 12px 5px;
          padding: 20px;
          border-radius: 8px;
        "
      >
        <b-col class="text-center" cols="12">
          <h1>คำนวณภาษี</h1>
          <h2>ช่วยคำนวณภาษีเงินได้นิติบุคคลประจำปี</h2>
        </b-col>

        <div class="tab-container">
          <div
            class="circle-tab"
            v-for="tab in tabs.slice(0, 2)"
            :key="tab"
            :class="{ active: activeTab === tab }"
            @click="changeTab(tab)"
          >
            {{ tab }}
          </div>
          <div
            class="active-indicator"
            :style="{
              transform: `translateX(calc(${(activeTab - 1) * 100}% + 50%))`,
            }"
          ></div>
        </div>

        <div class="tab-content">
          <div
            class="tab-pane"
            v-for="tab in tabs"
            :key="tab"
            :class="{ active: activeTab === tab }"
          >
            <div class="content-wrapper container" v-if="tab === 1">
              <div class="row">
                <div class="col-12">
                  <h1 class="text-colorh4">รายได้</h1>
                </div>
                <div class="col-12 col-md-6 offset-md-3">
                  <div class="input-label">
                    <h6 for="totalIncome">รายได้ทั้งหมด(ต่อเดือน)</h6>
                  </div>
                  <input
                    type="number"
                    class="form-control"
                    id="totalIncome"
                    placeholder="ระบุรายได้ทั้งหมด"
                    v-model="income"
                  />
                </div>

                <div class="col-12 col-md-6 offset-md-3 mt-3">
                  <div class="input-label">
                    <h6 for="capital">ทุนชำระแล้ว</h6>
                  </div>
                  <input
                    type="number"
                    class="form-control"
                    id="capital"
                    placeholder="ระบุทุน"
                    v-model="capital"
                  />
                </div>

                <div class="col-12 col-md-6 offset-md-3 mt-3">
                  <div class="input-label">
                    <h6 for="otherExpenses">ค่าใช้จ่ายทั้งหมด(ต่อเดือน)</h6>
                  </div>
                  <input
                    type="number"
                    class="form-control"
                    id="otherExpenses"
                    placeholder="ระบุค่าใช้จ่ายอื่นๆ"
                    v-model="otherExpenses"
                  />
                </div>

                <div class="col-12 mt-3">
                  <b-btn
                    v-if="
                      activeTab === 1 &&
                      (totalIncome || capital || otherExpenses)
                    "
                    @click="calculateTax"
                    >ยืนยัน</b-btn
                  >

                  <div
                    v-else-if="
                      activeTab === 1 &&
                      !(totalIncome || capital || otherExpenses)
                    "
                    class="text-danger"
                  >
                    กรุณากรอกข้อมูลในช่องอย่างน้อย 1 ช่อง
                  </div>
                </div>
              </div>
            </div>
            <div class="content-wrapper container" v-else-if="tab === 2">
              <b-row>
                <b-col class="text-center">
                  <h1 class="text-colorh4">คำนวณภาษี</h1></b-col
                >
              </b-row>

              <div class="row">
                <div class="col-4">
                  <h5>กำไรที่เหลือ</h5>
                </div>
                <div class="col-4">
                  <h5>
                    {{
                      getProfit !== null && getProfit !== undefined
                        ? getProfit.toLocaleString(undefined, {
                            style: "decimal",
                            useGrouping: true,
                          })
                        : "N/A"
                    }}
                  </h5>
                </div>
                <div class="col-4">
                  <h5>บาท</h5>
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <h5>ภาษีที่ต้องจ่าย</h5>
                </div>
                <div class="col-4">
                  <h5 style="color: brown">
                    {{
                      result !== null && result !== undefined
                        ? result.toLocaleString(undefined, {
                            style: "decimal",
                            useGrouping: true,
                          })
                        : "N/A"
                    }}
                  </h5>
                </div>
                <div class="col-4">
                  <h5>บาท</h5>
                </div>
              </div>
              <div class="rectangle-container py-3 py-md-5">
                <div class="rectangle">
                  <p v-if="showLink" style="color: #ac9a34">
                    หากผลการคำนวณภาษีเงินได้นิติบุคคลของคุณต้องเสียภาษีค่อนข้างสูง
                    <a
                      style="color: brown"
                      :href="linkToShow"
                      target="_blank"
                      rel="noopener noreferrer"
                      >สามารถดูตัวช่วยประหยัดภาษีเงินได้นิติบุคคลได้ที่นี่
                    </a>
                    เพื่อให้ประหยัดภาษีลง และหากต้องการคำแนะนำวางแผนเรื่องภาษี
                    บัญชี สามารถทักแชทได้ที่
                    <a
                      style="color: brown"
                      href="https://lin.ee/911BqsX"
                      target="_blank"
                      rel="noopener noreferrer"
                      >@ac567</a
                    >
                    หรือ
                    <a style="color: brown" href="tel:0910059903"
                      >โทร.091-005-9903</a
                    >
                    เพราะทุกปัญหาเรื่องภาษี บัญชีใกล้ตัวคุณมากกว่าที่คุณรู้
                    <a
                      style="color: brown"
                      href="https://lin.ee/911BqsX"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      ติดต่อหาเราโดยตรง</a
                    >
                  </p>
                </div>
              </div>

              <div class="col-12 col-md-6 offset-md-3 mt-3">
                <b-btn
                  variant="secondary"
                  @click="changeTab(1)"
                  class="back-button mr-2"
                  >ย้อนกลับ</b-btn
                >
                <b-btn v-if="activeTab === 2" @click="goBack"
                  >เริ่มทำใหม่</b-btn
                >
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
export default {
  data() {
    return {
      tabs: [1, 2],
      activeTab: 1,
      income: null,
      totalIncome: null,
      capital: null,
      otherExpenses: null,
      result: null,
      taxResult: null,
      showLink: false,
      totalAnnualIncome: null,
      linkToShow: "",
    };
  },
  computed: {
    isSmeBusiness() {
      return (
        this.isAnnualIncomeLessThanOrEqualThirtyMillion &&
        this.isCapitalLessThanOrEqualFiveMillion
      );
    },
    isAnnualIncomeLessThanOrEqualThirtyMillion() {
      return this.getAnnualIncome <= 30000000;
    },
    isCapitalLessThanOrEqualFiveMillion() {
      return this.capital <= 5000000;
    },
    getSmeBusinessLessThanThirtyThousandTaxRate() {
      return 0;
    },
    getSmeBusinessLessThanThreeMillionTaxRate() {
      if (this.getProfit > 300000) {
        const profit =
          this.getProfit > 3000000 ? 3000000 - 300000 : this.getProfit - 300000;
        console.log("2", profit * 0.15);
        return profit * 0.15;
      }
      return 0;
    },
    getSmeBusinessMoreThanThreeMillionTaxRate() {
      if (this.getProfit > 3000000) {
        const profit = this.getProfit - 3000000;
        console.log("3", profit * 0.2);
        return profit * 0.2;
      }
      return 0;
    },
    getSmeBusinessTaxCalculated() {
      return (
        this.getSmeBusinessLessThanThirtyThousandTaxRate +
        this.getSmeBusinessLessThanThreeMillionTaxRate +
        this.getSmeBusinessMoreThanThreeMillionTaxRate
      );
    },
    getAnnualIncome() {
      return this.income * 12;
    },
    getAnnualOtherExpense() {
      return this.otherExpenses * 12;
    },
    getProfit() {
      return this.getAnnualIncome - this.getAnnualOtherExpense;
    },
    getTaxCalculated() {
      if (this.getProfit >= 0) {
        if (!this.isSmeBusiness) {
          return this.getProfit * (20 / 100);
        }
        return this.getSmeBusinessTaxCalculated;
      }
      return 0;
    },
  },
  methods: {
    goBack() {
      this.tabs = [1, 2];
      this.activeTab = 1;
      this.totalIncome = null;
      this.capital = null;
      this.otherExpenses = null;
      this.taxResult = null;
      this.showLink = false;
      this.linkToShow = "";
      this.totalIncome = null;
      this.totalAnnualIncome = null;
      this.result = null;
    },
    calculateTax() {
      this.changeTab(2);

      this.result = this.getTaxCalculated.toLocaleString(undefined, {
        style: "decimal",
        useGrouping: true,
      });

      const link1 = "https://inflowaccount.co.th/set-up-company-without-money/";
      const link2 = "https://inflowaccount.co.th/set-up-company-without-money/";
      const randomLink = Math.random() < 0.5 ? link1 : link2;
      this.showLink = true;
      this.linkToShow = randomLink;
    },
    changeTab(tab) {
      this.activeTab = tab;
    },
    confirmTab1() {
      this.changeTab(2);
    },
    confirmTab2() {
      this.changeTab(3);
    },
  },
};
</script>

<style>
 .the-home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;
}


.header {
  background-color: rgba(0, 0, 0, 0.79);
  color: white;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}


  .logo {
    width: auto; 
    height: auto;
  }
  .main-content {
    margin-top: 70px; 
  }

.row {
  display: flex;
}

.column {
  flex: 1;
  padding: 20px;
  padding-left: 21px;
}

.rectangle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  /* padding-top: 150px;
  padding-bottom: 100px; */
}

.rectangle {
  width: 100%;
  height: auto;
  box-shadow: darkgrey 1px 1px 12px 5px;
  border: 10px solid rgba(0, 0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.text {
  font-size: 18px; /* ปรับขนาดตามต้องการ */
}
.input-label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 10px;
}
.button-spacing {
  margin-right: 80px;
}
.custom-select {
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-label h6 {
  margin-bottom: 0;
}
.btn-secondary {
  background-color: #ac9a34;
}
.tab-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 60px;
  margin-bottom: 20px;
}
.text-colorh4 {
  color: #ac9a34;
}

.circle-tab {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: lightgray;
  cursor: pointer;
  margin: 0 50px;
}

.circle-tab.active {
  background-color: #ac9a34;
  color: white;
}
.circle-tab {
  color: white;
}

.active-indicator {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50px;
  height: 2px;
  /* background-color: #007bff; */
  transition: transform 0.3s;
}
.tab-content {
  margin-top: 20px;
}

.tab-pane {
  display: none;
}

.tab-pane.active {
  display: flex;
  justify-content: center;
}

.content-wrapper {
  text-align: center;
}
.btn-secondary {
  color: #fff !important;
  background-color: #ac9a34 !important;
  border-color: #ac9a34 !important;
}
.btn-secondary:hover {
  color: #ac9a34 !important;
  background-color: #fff !important;
  border-color: #ac9a34 !important;
}
</style>
